import { React } from 'react'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'

function Home() {
    useEffect(() => {
        document.body.style.backgroundColor = "white"
    }, [])

    const navigate = useNavigate()
    const buttonCLick = () => {
        navigate("/login")
    }

    return (
        <>
            <div class="screen">
                <img class="logo" src="./Dirty.png" alt="logo"></img>
                <img id="profile-picture" src="./profile-picture.jpg" alt="profile pic"></img>
                <h1 id="title">
                    Maria B.'s Private Gallery
                </h1>
                <button class="go-ahead" onClick={buttonCLick}>
                    Sign in with Facebook
                </button>
            </div>
        </>
    )
}
export default Home