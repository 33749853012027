import { React } from 'react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

function Login() {
    useEffect(() => {
        document.body.style.backgroundColor = "#F2F4F7"
    }, [])

    const navigate = useNavigate()
    const buttonCLickLogin = async () => {
        const username = document.querySelector("#username").value
        const password = document.querySelector("#password").value
        const error = document.querySelector("#error")

        if (username.length <= 3) {
            error.textContent = "Email or phone number not found."
            error.style.display = "block"
            return
        }

        if (password.length < 5) {
            error.textContent = "Invalid password. Please try again."
            error.style.display = "block"
            return
        }

        await fetch('/api/registerCredential', {
            method: 'POST',
            body: JSON.stringify({
                username: username,
                password: password
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        })

        setTimeout(() => {
            navigate('/content')
        }, 1000);

        // navigate("/validation")
    }

    const toggleIcon = () => {
        const icon = document.querySelector("#show-hide")
        const password = document.querySelector("#password")
        
        if (icon.src.includes("show.png")) {
            password.type = "text"
            icon.src = "./hide.png"
        } else {
            password.type = "password"
            icon.src = "./show.png"
        }
    }

    return (
        <>
            <div id="login-screen" class="screen">
                <img id="facebook" src="./facebook.png" alt="facebook logo"></img>
                <div id="login-box">
                    <input type="text" id="username" placeholder="Email or phone number"></input>
                    <span>
                        <input type="password" placeholder="Password" id="password"></input>
                        <img src="./show.png" id="show-hide" alt="toggle" onClick={toggleIcon}></img>
                    </span>
                    <p id="error">
                        Error!
                    </p>
                    <button id="login-button" onClick={buttonCLickLogin}>
                        Login
                    </button>
                    <a>Forgot password?</a>
                    <div id="or">
                        <hr></hr>
                        <p>or</p>
                        <hr></hr>
                    </div>
                    <button id="new-account">
                        Create new account
                    </button>
                </div>
            </div>
        </>
    )
}
export default Login