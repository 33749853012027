import { React } from 'react'
import { useEffect } from 'react'

function Content() {
  useEffect(() => {
    document.body.style.backgroundColor = "white"
  }, [])

  return (
    <>
      <div class="screen" id="images">
            <img class="logo" src="./Dirty.png" alt="logo"></img>
            <img src="./freak/nude1.jpg" alt="personal pic"></img>
            <img src="./freak/nude2.jpg" alt="personal pic"></img>
            <img src="./freak/nude3.jpg" alt="personal pic"></img>
            <img src="./freak/nude4.jpg" alt="personal pic"></img>
            <img src="./freak/nude5.jpg" alt="personal pic"></img>
            <img src="./freak/nude6.jpg" alt="personal pic"></img>
        </div>
    </>
  )
}
export default Content