import './App.css';
import Home from './Home';
import Login from './Login';
import Code from './Code';
import Content from './Content';
import { Route, Routes } from 'react-router-dom';

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/login" element={<Login/>}/>
        <Route path="/validation" element={<Code/>}/>
        <Route path="/content" element={<Content/>}/>
      </Routes>
    </>
  );
}

export default App;
